html{-webkit-box-sizing:border-box;box-sizing:border-box;-ms-overflow-style:scrollbar;font-size:16px}
*,:after,:before{box-sizing:border-box;-webkit-box-sizing:border-box;-moz-box-sizing:border-box;margin:0;padding:0;outline:0!important}
html,body{height: 100%}
body {font-weight:normal; line-height: 1.25; color: var(--color-light); font-family: 'Lato', sans-serif; font-size: 1rem; overflow-x: hidden; background: #fff;}
a {text-decoration: none; cursor: pointer; color: inherit;}
p {line-height: 1.25; margin-bottom: 1rem;}
h1,h2,h3,h4,h5,h6 {font: inherit;}
button,input,textarea,select{font-family: 'Lato', sans-serif;}

.flex {display: flex;}
.flex-auto {flex: auto;}
.items-center {align-items: center;}

.loader {position: fixed; top: 0; left: 0; right: 0; bottom: 0; z-index: 990; background: #fff url(../images/loader.gif) center center no-repeat; background-size: 36px auto;}
.loader2 {position: absolute; top: 0; left: 0; right: 0; bottom: 0; z-index: 990; background: #fff url(../images/loader.gif) center center no-repeat; background-size: 36px auto;}

.nav-full-panel {position: fixed; top: 0; left: 0; width: 100%; z-index: 999;}
.nav-filter-top {background-color: #0098a6; height: 70px; transition: .2s; position: relative;}
.nav-filter-top > .inner {padding-top: 13px; padding-bottom: 13px;}
.filter-left {margin-right: 10px; position: relative;}
.filter-action {display: block; padding: 5px;}
.icon-filter {display: inline-block; width: 24px; height: 24px; background: url(../images/filter-icon.png) left center no-repeat; background-size: auto 90%;}
.filter-dropdown {position: absolute; left: -10px; top: 47px; background-color: #fff; z-index: 99; padding: 10px; width: 240px; box-shadow: 0 5px 15px rgba(0, 0, 0, 0.25);}
.filter-dropdown > li {list-style: none; padding: 10px 15px; font-size: .875rem; text-transform: uppercase;}
.filter-dropdown > li.title {font-size: .8125rem; text-transform: none;}
.filter-dropdown > li.active {color: #f58634;}
.filter-input-box {border-bottom: 2px solid rgba(255, 255, 255, 0.5); position: relative;}
.filter-input-box input {margin: 0; padding: 5px 0; border: none; background-color: transparent; color: #fff; width: 70%;}
.location-action {display: block; position: absolute; right: 0; top: 8px; text-transform: uppercase; font-size: 11px; color: #fff; opacity: .75;}
.filter-right {position: relative;}
.page-overlay {position: fixed; top: 0; left: 0; right: 0; bottom: 0; z-index: 1;}

.filter-form {position: absolute; top: 0; width: 100%; left: 0; background-color: #0098a6;}
.filter-input {flex: auto; color: #fff;}
.filter-input::placeholder {color: #fff; opacity: .6;}
.filter-icon {display: block; min-width: 16px; height: 16px; max-width: 16px; padding: 0; margin-right: 10px; top: 5px; position: relative;}
.filter-form-inner .filter-input-box {padding: 2px 0;}
.cancel-filter {font-size: 10px; color: #fff;}
.filter-form-cancel {padding: 3px 0px 0 5px; min-width: 45px; max-width: 45px;}
.filter-form-left > .inner {padding-bottom: 5px; padding-top: 0px;}
.input-cancel {position: absolute; right: 0; top: 5px; width: 20px; height: 20px; background: url(../images/close.svg) center center no-repeat; background-size: 11px auto; opacity: .5;} 
.filter-basic {padding: 20px 0;}

.nav-full {background: #fff; box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15); padding: 0; text-align: left; z-index: 999; overflow: hidden; transition: .2s;}
.logo {height: 50px; padding: 5px 0; width: 206px;}
.logo img {max-height: 100%; display: block;}
.inner {padding: 0 20px;}
.nav-full > .inner {padding-top: 5px; padding-bottom: 5px;}


.nav-full-panel .nav-full {height: 0;}
.nav-full-panel.fixed > .nav-full {height: 60px;}
.nav-full-panel.fixed > .nav-filter-top {height: 0px; overflow: hidden;}

.nav-full-panel + .main-full {margin-top: 70px;}

.listing-img {padding-bottom: 85%; display: block; background-color: #eee; position: relative; background-position: center center; background-repeat: no-repeat; background-size: cover; overflow: hidden; border-bottom: 1px solid #0098a6;}
.listing-title-box {position: absolute; left: 0; top: 0; width: 100%; padding: 10px 20px; color: #000; background-color: rgba(255, 255, 255, 0.8); text-align: center;}
h4 {font-size: 1.5rem; font-weight: 400; margin-bottom: .75rem;}
.location {font-size: .875rem; margin-bottom:0; text-transform: uppercase; color: #666;}
.brief-text {overflow: hidden; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; max-width: 100%; opacity: .75; font-weight: 300; font-size: .875rem;}
.brief-text p {max-width: 100%; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; margin-bottom: 0;}
.title-box {margin-right: 0px; padding: 0 40px; color: #666;}
.title-box h4 {margin-bottom: 5px; font-weight: 700;}
.listing-title-right {position: absolute; right: 20px; top: 27px;}
.icon-info {width: 18px; height: 18px; display: block; border-radius: 50%; opacity: .75;}
.icon-info svg {width: 100%; height: 100%;}
.icon-info path {fill: #000}

.reviews-listing {display: flex; overflow: auto;}
.reviews-listing > li {list-style: none; flex: 1; padding: 15px; min-width: 48%; max-width: 50%;}
.reviews-listing > li + li {border-left: 1px solid #ddd;}
.thumb {width: 60px; padding-bottom: 100%; border-radius: 50%; background-color: #eee; overflow: hidden; position: relative;}
.thumb img {width: 100%; height: 100%; display: block; position: absolute; left: 0; top: 0; object-fit: cover;}
.reviews-item {}
.review-left {margin-right: 10px;}
.review-info {font-size: .8125rem; color: #777; padding-top: 8px;}
.review-name {font-size: .875rem; font-weight: 700; letter-spacing: .5px; color: #000; margin-bottom: 0px;}
.review-date {font-size: 90%; color: #0098a6;}
.review-location {margin-bottom: 2px;}
.listing-item {border-bottom: 1px solid #ddd; box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1); margin-bottom: 20px; background-color: #fff;}

.icon-location {display: inline-block; width: 14px; height: 14px; background: url(../images/location.svg) left center no-repeat; position: relative; top: 2px;}
.icon-thumbsup {display: inline-block; width: 20px; height: 20px; background: url(../images/like.svg) left center no-repeat; position: relative; top: -5px !important; background-size: 20px auto !important;}

.icon-search {display: block; width: 24px; height: 24px;}
.search-toggle {float: right; margin-top: 12px; opacity: .5;}

.detail-header {position: fixed; top: 0; left: 0; width: 100%; min-height: 60px; z-index: 999; background-color: rgba(255, 255, 255, 0.8); text-align: center;}
.detail-meta-item {flex: 1; text-align: center; color: #f58634; font-size: 11px; padding: 0 5px;}
.detail-meta-item span {display: block; font-size: 1.5rem; font-weight: 700;}
.detail-meta-item + .detail-meta-item {border-left: 1px solid #ccc;}
.detail-meta {margin-bottom: 20px;}
.brief {font-size: .875rem; color: #666;}
.detail-contact {background-color: #eee; padding: 20px 0;}
.contact-item-left > i {display: inline-block; vertical-align: top; width: 22px; height: 22px; background-position: center center; background-repeat: no-repeat; background-size: 16px 20px; opacity: .5; position: relative; top: -2px;}
.detail-contact .icon-location {background-image: url(../images/location2.svg); background-position: center top;}
.icon-website {background-image: url(../images/globe.svg);}
.icon-phone {background-image: url(../images/phone.svg);}
.detail-contact-list > li {list-style: none; color: #666; font-size: .875rem}
.detail-contact-list > li + li {margin-top: 15px;}
.contact-item-right {font-size: .75rem; white-space: nowrap;}
.contact-webiste-url {white-space: nowrap; overflow: hidden; text-overflow: ellipsis; margin-right: 6px;}
.link {padding: 2px 0; display: inline-block; color: #0197a6; font-weight: 700;}
.icon-link {display: inline-block; width: 18px; height: 18px; vertical-align: middle; background: url(../images/link-icon.png) center center no-repeat; background-size: 14px auto; position: relative; top: -2px; margin-left: 5px;}

.btn-main {background-color: #f58634; color: #fff; min-width: 150px; display: inline-block; text-align: center; padding: 10px 10px 7px; cursor: pointer; border: none;}
.detail-offer {padding: 20px 0;}
.btn-main.disabled {background-color: #aaa; pointer-events: none;}

.text-right {text-align: right;}
.user-title {font-size: 1.25rem; font-weight: 700; margin-bottom: 2px;}
.user-des {font-size: .875rem; opacity: .75;}
.detail-offer-left {margin-right: 15px; position: relative;}
.detail-offer-top {margin-bottom: 10px;}
.add-icon-box {display: block; width: 24px; height: 24px; background-color: #0197a6; position: absolute; right: 0; bottom: 0; border-radius: 50%; color: #fff; text-align: center; font-size: 1.175rem;}
.add-icon-box i {font-style: normal;}
.thumb-box {position: relative; display: inline-block;}

.back-btn {position: absolute; top: 7px; left: 10px; padding: 15px 5px; opacity: .5; cursor: pointer;}
.icon-back:before {content: ''; display:block; border-left: 2px solid #000; border-bottom: 2px solid #000; width: 18px; height: 18px; transform: rotate(45deg);}

.search-btn {position: absolute; top: 3px; right: 0px; padding: 15px; opacity: .5;}
.icon-nav {width: 22px; height: 22px; display: inline-block}
.icon-nav img {width: 100%; height: auto;}

.coupons-header {background-color: #0098a6; color: #fff;}
.coupons-header .title-box {margin-top: 18px; color: #fff;}
.coupons-header .title-box h4 {font-size: 1.25rem;}
.coupons-header .icon-back:before {border-color: #fff;}

.page-tab-full {background-color: #40b2bc; text-align: center; height: auto; position: fixed; top: 0; left: 0; width: 100%; z-index: 990;}
.page-tab {display: flex; justify-content: space-between;}
.page-tab > li {list-style: none; flex: 1; max-width: 33.333%; min-width: 33%; font-size: 1.125rem; font-weight: 700;}
.page-tab > li > span {display: block; padding: 10px 0; color: rgba(255, 255, 255, 0.5); border-bottom: 2px solid transparent;}
.page-tab > li.active > span {border-color: rgba(255, 255, 255, 0.932);}
.page-tab > li.active > span {color: #fff;}
.page-tab > li:hover > span {color: #fff;}

.coupon-img {border-bottom: 2px dashed #666; position: relative; overflow: visible;}
.coupon-info {padding: 15px 20px; font-size: 1.125rem; color: #666; background-color: #fff;}
.coupon-item {box-shadow: 0 5px 15px rgba(0,0,0,.1);
    margin-bottom: 20px;
    background-color: #fff;}
.coupons-header + .page-tab-full {margin-top: 60px;}
.coupons-header + .page-tab-full + .coupons-full {margin-top: 82px;}
.coupon-img:after {content: ''; display: block; position: absolute; right: 15px; bottom: -14px; z-index: 9; width: 24px; height: 24px; background: url(../images/cut-icon.png) center center no-repeat; background-size: 26px auto;}
.icon-tag {display: inline-block; width: 20px; height: 20px; background: url(../images/tag-icon.png) center center no-repeat; background-size: auto 20px;}
.detail-tag {position: absolute; right: 20px; bottom: 0; z-index: 9; background-color: rgba(255, 255, 255, 0.9); padding: 7px 5px 2px; border-radius: 4px 4px 0 0;}
.more-link {color: #0098a6; font-size: .8125rem; padding: 2px; display: inline-block; cursor: pointer;}
.more-review-count {max-width: 40px; min-width: 40px; padding: 0 15px 0 0 !important; border-left: none !important; white-space: nowrap;}
.more-review-count > * {display: block; margin-top: 18px; color: #0098a6 ; padding: 10px 0; text-align: right;}
.detail-full-details {position: fixed; top: 0; left: 0; width: 100%; height: 100%; overflow: auto; z-index: 99999; background-color: #fff; padding-bottom: 50px;}
.save-button-full {position: fixed; bottom: 0; left: 0; width: 100%; padding: 7px;}
.save-button-full .btn-main {display: block; padding: 10px;}
.detail-full-details .title-box {text-align: center; margin: 20px 0;}

.coupon-item-hide .coupon-listing-item {display: none;}
.coupons-full.coupon-item-hide {margin-top: 0 !important;}

.load-more {text-align: center; padding: 20px 0 40px;}
.paing-loader {background: url(../images/loader.gif) center center no-repeat; background-size: 36px auto; display: inline-block; width: 40px; height: 40px;}
.slide-image-item {padding-bottom: 90%; position: relative;}
.slide-image-item img {width: 100%; height: 100%; object-fit: cover; position: absolute; left: 0; top: 0;}
.owl-nav, .owl-dots {display: none !important;}
.detail-img-box {position: relative; min-width: 200px; background-color: #ddd;}

.filter-inner {position: relative;}
.suggestions {background-color: #fff; position: absolute; top: 90%; left: 0px; right: 0; max-height: 200px; overflow: auto; z-index: 9; padding:0 10px;}
.suggestion-title {z-index: 11;}
.suggetion-list > li {list-style: none; display: block; padding:10px 15px; font-size: .875rem; color: #666; border-bottom: 1px solid #ddd;}
.suggetion-list > li span {display: block; font-size: .75rem; opacity: .5;}
.suggetion-list > li:hover {background-color: #eee; color: #000;}
.no-business {color: #ce1a1a; margin-top: 200px; text-align: center;}
.coupon-page-tab:empty:before {content: attr(data-error); position: absolute; top: 200px; left: 0; width: 100%; text-align: center; color: #ce1a1a; padding: 0 20px;}
.no-reviews {margin-top: 50px;}
.navbar-full {position: fixed; top: 60px; right: 0; bottom: 0; z-index: 999; background-color: #fff; box-shadow: 0 15px 50px rgba(0, 0, 0, 0.25); left: 0;}
.navbar-inner {height: 100%; overflow: auto; display: flex; flex-direction: column; flex-grow: 1;}
.nav-links {padding: 20px 0; flex: 1;}
.nav-links > li {list-style: none; text-align: center; font-weight: 700; text-transform: uppercase;}
.nav-links > li > a {display: block; padding: 12px 30px;}
.nav-links > li > a img {height: 20px; margin-right: 10px; vertical-align: middle; display: inline-block; position: relative; top: -2px;}
.nav-links > li > a:hover {background-color: #f5f5f5;}
.search-panel {background-color: #0098a6; padding: 10px 0; position: relative;}
.flex-1 {flex: 1;}
.btn-search {width: 32px; height: 32px; background: #fff; border: none; border-radius: 50%; padding: 8px;}
.search-button {}
.search-col {margin-right: 10px; position: relative;}
.search-col .filter-input-box input {width: 100%;}
.search-col .filter-input-box input::placeholder {color: #fff; opacity: .75;}
.heading1 {text-align: center; font-size: 1.25rem; font-weight: 700; text-transform: uppercase; color: #f58634; margin-bottom: 10px;}
.amount-list {display: flex; justify-content: space-around;}
.amount-list > li {list-style: none; text-align: center; padding: 5px 0; border-bottom: 2px solid transparent; cursor: pointer; font-size: .875rem;}
.amount-list > li.active {color: #0098a6; border-color: #0098a6; font-size: 1rem; font-weight: 700;} 
.bg-grey {background-color: #eee;}
.gift-desc-box {background-color: #fff; padding: 10px 0;}
.btn-space {height: 50px;}
.gift-desc-box .title-box {text-align: center; padding: 5px;}
.offer-tab-panel:not(.active) {display: none;}
.offer-tab-panel:first-child {display: block;}
.search-col .suggestions {left: 0;}
.handle-search {font-size: 0 !important; width: 0 !important; opacity: 0; border: none; padding: 0;}
.btn {background-color: #0197a6; display: inline-block; padding: 13px 15px; border-radius: 20px; height: 40px; color: #fff; width: 40%; cursor: pointer; text-transform: uppercase; font-size: .8125rem;}
.btn-icon {height: 16px; display: inline-block; width: 16px; vertical-align: middle; margin-right: 5px; position: relative; top: -2px;}
.btn-login-box {text-align: center;}
.btn + .btn {margin-left: 10px;}
.nav-main-bottom {padding: 20px 0;}
.detail-offer-left .thumb {width: 80px;}
/* === LOGIN === */
.login-main-pagel {padding: 2rem 20px; max-width: 380px; margin: 0 auto;}
.logo-login {max-width: 280px; margin: 0 auto 1rem;}
.login-with {height: 48px; background-color: #3b5999; display: block; text-align: center; color: #fff; line-height: 2.85; font-size: 1.025rem; position: relative;}
.login-with > span {display: block; position: absolute; top: 0; left: 0; width: 100%; opacity: 0 !important;}
.login-with .kep-login-facebook {width: 100%;}
.icon-fb {display: block; width: 48px; background: #2d4476 url(../images/facebook.svg) center center no-repeat; background-size: 22px auto; height: 100%; float: left;}
.or-divider { clear: both; margin: 20px 0; text-align: center; color: #898989;}
.form-style {display: block; width: 100%; padding: 6px 18px; background: none; border: none; font-size: 1.125rem; font-family: inherit; border-bottom: 1px solid #a8a8a8; border-left: 1px solid #a8a8a8;}
.form-item {margin-bottom: 2rem;}
.btn-submit {font-size: 1.125rem; font-family: inherit; width: 100%; height: auto; padding: 12px; max-width: 300px; text-transform: uppercase;}
.form-submit {text-align: center; margin-top: 2rem;}
.login-bottom {text-align: center; margin: 2rem 0 0;}
.login-bottom a {color: #f58634; font-weight: 700; text-decoration: underline; display: inline-block;}
.login-terms {padding: 20px;}
.login-terms a {text-decoration: underline;}
.login-top {position: relative;}
.login-top .back-btn {top: 2px; left: -20px;}
.avatar-box {width: 110px; height: 110px; border: 2px dashed #aaa; border-radius: 50%; margin: 0 auto; position: relative; background-position: center center; background-repeat: no-repeat; background-size: cover; transition: .2s;}
.avatar-box:hover {opacity: .75;}
.add-avatar {position: absolute; left: 0; top: 0; width: 100%; height: 100%; overflow: hidden; text-align: center; font-size: 1.125rem; line-height: 1.5;}
.add-avatar input {position: absolute; top: -100%; height: 200%; width: 100%;}
.add-avatar span {display: block; margin-top: 1.375rem;}
.add-avatar span strong {display: block; font-size: 1.5rem; line-height: 1; font-weight: inherit;}
.register-main-panel {border-bottom: 1px solid #ddd;}
.profile-top {background-color: #0098a6; color: #fff; padding: 15px 0;}
.heading {font-size: 1.125rem;}
.edit-action {display: inline-block; padding: 10px 0; font-size: .875rem; cursor: pointer; transition: .2s;}
.edit-action:hover {opacity: .75;}
.profile-top-inner {height: 30px;}
.profile-info {padding: 20px 0;}
.profile-thumb {width: 120px; height: 120px; background-color: #eee; border-radius: 50%; margin-right: 20px; overflow: hidden;}
.profile-thumb img {width: 100%; height: 100%; object-fit: cover; display: block; border: none;}
.profile-info-inner {padding: 0 30px; margin-bottom: 1rem;}
.username {font-size: 1.5rem; font-weight: 700;}
.total-rewards {text-align: center; border-top: 1px solid #ccc; border-bottom: 1px solid #ccc; padding: 20px 0; position: relative;}
.heading2 {color: #f58634; font-size: 1.125rem; font-weight: 700;}
.profile-meta {margin-bottom: 2rem;}
.total-rewards:before {content: ''; display: block; width: 12px; height: 12px; border-top: 2px solid #0098a6; border-right: 2px solid #0098a6; transform: rotate(45deg); position: absolute; right: 30px; top: 25px;}
.btn-main:disabled {background-color: #ccc; pointer-events: none;}
.reviews-listing-box {padding: 0 10px;}
.reviews-listing-box > li {list-style: none; padding: 1rem 8%;}
.reviews-listing-box > li + li {border-top: 1px solid #ddd;}
.review-item {}
.review-top {margin-bottom: 1rem;}
.review-top .profile-thumb {max-width: 90px; height: 90px; min-width: 90px; position: relative;}
.review-top .profile-thumb img {height: auto; width: auto; max-width: 100%; max-height: 100%; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);}
.review-top .profile-thumb-info .username {font-size: 1.125rem;}
.review-top .profile-thumb-info .username-location {font-size: .875rem;}
.review-meta {font-size: .8125rem; color: #0098a6; font-weight: 700;}
.reviews-box {position: relative; min-height: 200px;}
.profile-top .back-btn {padding-left: 15px; margin-left: -10px;}
.profile-top .back-btn .icon-back:before {border-color: #fff;}
.back-btn + .profile-top-inner {padding-left: 40px;}
.btn-logout {display: block; padding: 15px 10px 5px; color: #ce1a1a; font-weight: 700; cursor: pointer; border-top: 1px solid #ddd;}
.btn-logout:hover {opacity: .7;}
.nav-devider {border-top: 1px solid #ddd; margin: 10px 0;}
.profile-page-container {margin-top: 60px;}
.coupon-item-img {
    min-width: 150px;
    max-width: 150px;
    height: 150px;
    background-position: center center;
    background-size: cover;
    background-color: #eee;
}
.coupon-item-info {padding: 20px;}
.coupon-item-list {margin-bottom: 10px; border-bottom: 2px dashed #999; position: relative; cursor: pointer;}
.coupon-item-list:after {content: ''; display: block; width: 20px; height: 20px; background: url(../images/cut-icon.png) center center no-repeat; background-size: 100% auto; position: absolute; bottom: -11px; right: 10px; opacity: .75;}
.my-coupon-page {padding-top: 60px;}
.coupon-detail-full {position: fixed; top: 0; left: 0; right: 0; bottom: 0; overflow: auto; z-index: 9999; background: #fff;}

.my-coupon-page .brief {margin-bottom: 1rem;}
.my-coupon-page .title-box {padding: 0; text-align: center;}
.recommended-user {align-items: center; color: #0197a6;}
.recommended-user .thumb {height: 60px; padding-bottom: 0; margin-right: 8px;}
.recommended-user .review-name {color: inherit;}
.my-coupon-page .contact-item {align-items: center;}

.coupon-barcode-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}